import React from 'react';
import '../../../assets/css/toastnotification.css';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../../../store/reducers/commonSlice';

function Toast() {
  const { showMessage, message, varient } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (showMessage === true) {
      setTimeout(s => {
        dispatch(hideMessage());
      }, 3000);
    }
  }, [dispatch, showMessage]);

  return (
    <div className={showMessage === true ? 'show' : ''} id="toast_custom">
      <div className=" flex justify-center items-center " id="img">
        {varient === 'success' ? (
          <i className="fa-solid fa-circle-check"></i>
        ) : (
          <i className="fa-solid fa-circle-info"></i>
        )}
      </div>
      <div id="desc">{message !== undefined && message.toString()}</div>
    </div>
  );
}
export default Toast;
