import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'app';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/User.css';
import { configureAppStore } from './store/configureStore';
import { Provider } from 'react-redux';
import Toast from 'app/components/ToastNotification';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureAppStore();
root.render(
  <Provider store={store}>
    <Toast />
    <App />
  </Provider>,
);
