/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import reported from './reducers/ReportedSlice/reportedSlice';
import wikilyf from './reducers/ReportedSlice/wikilyfSlice';
import common from './reducers/commonSlice';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  // if (Object.keys(injectedReducers).length === 0) {
  //   return state => state;
  // } else {
  return combineReducers({
    ...injectedReducers,
    reported,
    wikilyf,
    common,
  });
  // }
}
