import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../commonSlice';

const initialState = {
  wikiUserList: [],
  wikiUserListLoading: false,
  wikiDetailList: [],
  wikiDetailListLoading: false,
  wikiHistoryList: [],
  wikiHistoryListLoading: false,
  isWikiDetailSuccess: false,
  wikiHistoryDetailList: [],
  wikiHistoryDetailListLoading: false,
  isWikiDetailHistorySuccess: false,
};

export const getWikiList = createAsyncThunk(
  'wiki-list',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { start, limit, search } = data;
      let newObj = { search: search ? search : '' };
      axios
        .post(`/wiki-lyf/wiki-list/${start}/${limit}`, newObj)
        .then(resData => {
          if (resData.status === 200) {
            let { Data, ReturnMsg } = resData.data;
            dispatch(showMessage({ message: ReturnMsg, varient: 'success' }));
            resolve(Data);
          } else {
            reject(resData);
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.ReturnMsg }));
          reject(error);
        });
    });
  },
);

export const getWikiHistory = createAsyncThunk(
  'wiki-history',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { start, limit, mobile_number } = data;
      let newObj = { mobile_number: mobile_number };
      axios
        .post(`/wiki-lyf/wiki-history/${start}/${limit}`, newObj)
        .then(resData => {
          if (resData.status === 200) {
            let { Data, ReturnMsg } = resData.data;
            dispatch(showMessage({ message: ReturnMsg, varient: 'success' }));
            resolve(Data);
          } else {
            reject(resData);
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.ReturnMsg }));
          reject(error);
        });
    });
  },
);

export const getWikiDetail = createAsyncThunk(
  'wiki-detail',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { start, limit, url } = data;
      let newObj = { url: url };
      axios
        .post(`/wiki-lyf/wiki-detail/${start}/${limit}`, newObj)
        .then(resData => {
          if (resData.status === 200) {
            let { Data, ReturnMsg } = resData.data;
            dispatch(showMessage({ message: ReturnMsg, varient: 'success' }));
            resolve(Data);
          } else {
            reject(resData);
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.ReturnMsg }));
          reject(error);
        });
    });
  },
);
export const getWikiHistoryDetail = createAsyncThunk(
  'wiki-history-detail',
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { start, limit, url, version } = data;
      let newObj = { url: url, version: version };
      axios
        .post(`/wiki-lyf/wiki-history-detail/${start}/${limit}`, newObj)
        .then(resData => {
          if (resData.status === 200) {
            let { Data, ReturnMsg } = resData.data;
            dispatch(showMessage({ message: ReturnMsg, varient: 'success' }));
            resolve(Data);
          } else {
            reject(resData);
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.ReturnMsg }));
          reject(error);
        });
    });
  },
);
export const wikilyfSlice = createSlice({
  name: 'wikilyf',
  initialState,
  reducers: {
    setIsWikiDetailSuccess: (state, action) => {
      state.isWikiDetailSuccess = action.payload;
    },
    setIsWikiDetailHistorySuccess: (state, action) => {
      state.isWikiDetailHistorySuccess = action.payload;
    },
  },
  extraReducers: {
    //quantity setting
    [getWikiList.pending]: (state, action) => {
      state.wikiUserList = [];
      state.wikiUserListLoading = true;
    },
    [getWikiList.rejected]: (state, action) => {
      state.wikiUserList = [];
      state.wikiUserListLoading = false;
    },
    [getWikiList.fulfilled]: (state, action) => {
      state.wikiUserList = action.payload;
      state.wikiUserListLoading = false;
    },
    [getWikiDetail.pending]: (state, action) => {
      state.wikiDetailList = [];
      state.wikiDetailListLoading = true;
      state.isWikiDetailSuccess = false;
    },
    [getWikiDetail.rejected]: (state, action) => {
      state.wikiDetailList = [];
      state.wikiDetailListLoading = false;
      state.isWikiDetailSuccess = false;
    },
    [getWikiDetail.fulfilled]: (state, action) => {
      state.wikiDetailList = action.payload;
      state.wikiDetailListLoading = false;
      state.isWikiDetailSuccess = true;
    },
    [getWikiHistory.pending]: (state, action) => {
      state.wikiHistoryList = [];
      state.wikiHistoryListLoading = true;
    },
    [getWikiHistory.rejected]: (state, action) => {
      state.wikiHistoryList = [];
      state.wikiHistoryListLoading = false;
    },
    [getWikiHistory.fulfilled]: (state, action) => {
      state.wikiHistoryList = action.payload;
      state.wikiHistoryListLoading = false;
    },
    [getWikiHistoryDetail.pending]: (state, action) => {
      state.wikiHistoryDetailList = [];
      state.wikiHistoryDetailListLoading = true;
      state.isWikiDetailHistorySuccess = false;
    },
    [getWikiHistoryDetail.rejected]: (state, action) => {
      state.wikiHistoryDetailList = [];
      state.wikiHistoryDetailListLoading = false;
      state.isWikiDetailHistorySuccess = false;
    },
    [getWikiHistoryDetail.fulfilled]: (state, action) => {
      state.wikiHistoryDetailList = action.payload;
      state.wikiHistoryDetailListLoading = false;
      state.isWikiDetailHistorySuccess = true;
    },
  },
});
export const { setIsWikiDetailSuccess, setIsWikiDetailHistorySuccess } =
  wikilyfSlice.actions;
export default wikilyfSlice.reducer;
