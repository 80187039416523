import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage/index';
const Header = React.lazy(() =>
  import('./components/UserScreen/Header/Header'),
);
const Home = React.lazy(() => import('./components/UserScreen/Pages/Home'));
const Dashboard = React.lazy(() =>
  import('./components/UserScreen/Pages/Dashboard'),
);
const Community = React.lazy(() =>
  import('./components/UserScreen/Pages/Community'),
);
const ReportedChats = React.lazy(() =>
  import('./components/UserScreen/Pages/ReportedChats'),
);
const SearchResult = React.lazy(() =>
  import('./components/UserScreen/Pages/SearchResult'),
);
// const Footer = React.lazy(() =>
//   import('./components/UserScreen/Footer/Footer'),
// );
const Chat = React.lazy(() => import('./components/UserScreen/Pages/Chat'));
const Wikilyf = React.lazy(() =>
  import('./components/UserScreen/Pages/Wikilyf'),
);
const WikilyfPublished = React.lazy(() =>
  import('./components/UserScreen/Pages/WikilyfPublished'),
);
const WikilyfDetail = React.lazy(() =>
  import('./components/UserScreen/Pages/WikilyfDetail'),
);
const Loader = React.lazy(() => import('../loader/index'));

import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'http://ec2-54-90-110-20.compute-1.amazonaws.com:3000/web/';

export function App() {
  return (
    <BrowserRouter>
      <Header />
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/community" element={<Community />} />
          <Route path="/reportedchats" element={<ReportedChats />} />
          <Route path="/wikilyf" element={<Wikilyf />} />
          <Route
            path="/wikilyf-published/:mobileNo"
            element={<WikilyfPublished />}
          />

          <Route path="/wikilyf-detail/:url" element={<WikilyfDetail />} />
          <Route
            path="/wikilyf-detail/:url/:version"
            element={<WikilyfDetail />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}
