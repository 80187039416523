import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  userList: [],
  userListLoading: false,
  communityList: [],
  communityListLoading: false,
  userChatList: [],
  userChatListLoading: false,
  messageListOfUser: [],
  messageListOfUserLoading: false,
  selectedUserId: '',
};

export const getUserList = createAsyncThunk('User', data => {
  return new Promise((resolve, reject) => {
    const { start, limit } = data;
    axios
      .get(`reported/user/${start}/${limit}`)
      .then(resData => {
        if (resData.status === 200) {
          let { Data } = resData.data;
          resolve(Data);
        } else {
          reject(resData);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
});

export const getCommunityList = createAsyncThunk('Community', data => {
  return new Promise((resolve, reject) => {
    const { start, limit } = data;
    axios
      .get(`reported/community/${start}/${limit}`)
      .then(resData => {
        if (resData.status === 200) {
          let { Data } = resData.data;
          resolve(Data);
        } else {
          reject(resData);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
});

export const getUserChatList = createAsyncThunk('UserChatList', data => {
  return new Promise((resolve, reject) => {
    const { start, limit } = data;
    axios
      .get(`reported/user-chat-list/${start}/${limit}`)
      .then(resData => {
        if (resData.status === 200) {
          let { Data } = resData.data;
          resolve(Data);
        } else {
          reject(resData);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
});

export const getMessageListOfUser = createAsyncThunk('message', data => {
  return new Promise((resolve, reject) => {
    const { start, limit, userId } = data;
    axios
      .get(`reported/message/${userId}/${start}/${limit}`)
      .then(resData => {
        if (resData.status === 200) {
          let { Data } = resData.data;
          resolve(Data);
        } else {
          reject(resData);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
});
export const reportedSlice = createSlice({
  name: 'reported',
  initialState,
  reducers: {
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
      sessionStorage.setItem('selectId', JSON.stringify(action.payload));
    },
    // getLoginInfo: (state, action) => {
    //   const userInfo = JSON.parse(sessionStorage.getItem('selectId')) || '';
    //   state.selectedUserId = userInfo;
    // },
    // removeLoginInfo: (state, action) => {
    //   sessionStorage.removeItem('selectId');
    //   state.selectedUserId = '';
    // },
  },
  extraReducers: {
    //quantity setting
    [getUserList.pending]: (state, action) => {
      state.userList = [];
      state.userListLoading = true;
    },
    [getUserList.rejected]: (state, action) => {
      state.userList = [];
      state.userListLoading = false;
    },
    [getUserList.fulfilled]: (state, action) => {
      state.userList = action.payload;
      state.userListLoading = false;
    },
    [getCommunityList.pending]: (state, action) => {
      state.communityList = [];
      state.communityListLoading = true;
    },
    [getCommunityList.rejected]: (state, action) => {
      state.communityList = [];
      state.communityListLoading = false;
    },
    [getCommunityList.fulfilled]: (state, action) => {
      state.communityList = action.payload;
      state.communityListLoading = false;
    },
    [getUserChatList.pending]: (state, action) => {
      state.userChatList = [];
      state.userChatListLoading = true;
    },
    [getUserChatList.rejected]: (state, action) => {
      state.userChatList = [];
      state.userChatListLoading = false;
    },
    [getUserChatList.fulfilled]: (state, action) => {
      state.userChatList = action.payload;
      state.userChatListLoading = false;
    },
    [getMessageListOfUser.pending]: (state, action) => {
      state.messageListOfUser = [];
      state.messageListOfUserLoading = true;
    },
    [getMessageListOfUser.rejected]: (state, action) => {
      state.messageListOfUser = [];
      state.messageListOfUserLoading = false;
    },
    [getMessageListOfUser.fulfilled]: (state, action) => {
      state.messageListOfUser = action.payload;
      state.messageListOfUserLoading = false;
    },
  },
});
export const { setSelectedUserId, getLoginInfo } = reportedSlice.actions;
export default reportedSlice.reducer;
